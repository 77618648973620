.manager {
    padding: 65px 0 60px 0;
    width: 100%;
    min-height: 800px;
    background-size: cover !important;
    background: url("../img/manager-bg.png") center no-repeat;
    @include sm {
        padding: 65px 0 90px 0; }

    &-row {
        padding-top: 41px; }
    &-box {
        overflow: scroll;
        height: 500px;
        overflow-x: hidden;
        &::-webkit-scrollbar-track {
            background-color: rgba(#ffffff,0.25); }
        &::-webkit-scrollbar {
            width: 2px;
            background-color: rgba(#ffffff,0.25); }
        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #333333;
            background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%); } }
    &-text {
        color: #ffffff;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 26px;
        padding-right: 30px;
        line-height: 22px;
        @include sm {
            font-size: 14px; } }
    &-subtitle {
        width: 210px;
        height: 71px;
        background: url("../img/bg-title.png") center no-repeat;
        background-size: cover;
        text-align: center;
        padding: 12px 0;
        color: #333333;
        font-family: 'GothamPro-Bold';
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        z-index: 10;
        position: relative;
        top: 30px; }

    &-item {
        justify-content: flex-end;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        left: 20px;
        @include lg {
            display: none; } }
    &-desc {
        width: 210px;
        height: 71px;
        background: url("../img/desc-bg.png") center no-repeat;
        background-size: cover;
        color: #333333;
        padding: 12px 0;
        position: relative;
        left: 10px;
        text-align: center;
        font-family: 'GothamPro-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 28px; }
    &-link {
        color: #e13e46;
        font-family: 'GothamPro-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-decoration: underline;
        @include sm {
            font-size: 14px; }
        &:hover {
            color: #e12e46; } }
    &-span {
        color: #777676;
        font-size: 12px;
        text-decoration: none;
        padding-left: 5px; }
    &-group {
        display: flex;
        margin-top: 35px;
        @include sm {
            flex-wrap: wrap;
            justify-content: center; } }
    &-block {
        padding-top: 30px; }
    &-nav {
        ul {
            list-style-type: none;
            padding-left: 10px;
            li {
                color: #ffffff;
                font-family: 'MullerRegular';
                font-size: 16px;
                font-weight: 400;
                padding-bottom: 10px;
                padding-right: 30px;
                line-height: 22px;
                @include sm {
                    font-size: 14px; } } } } }
