.question {
    padding: 58px 0 100px 0;
    background-color: #fbfbfb;
    @include md {
        padding: 50px 0 100px 0; }
    &-box {
        background-color: #ffffff;
        padding: 10px;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        position: relative;
        border-radius: 5px;
        width: 100%;
        margin-top: 47px;
        z-index: 1;

        @include sm {
            padding: 5px;
            margin-bottom: 60px; } }
    &-shadow {
        display: block;
        position: absolute;
        bottom: 125px;
        width: 94%;
        left: 3%;
        z-index: 0;
        height: 100px;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background-color: #ffffff;
        @include lg {
            bottom: 135px; }
        @include md {
            bottom: 145px;
            width: 89%;
            left: 5.5%; }
        @include sm {
            bottom: 190px; }
        @include mobile {
            bottom: 200px; }
        @include xs {
            bottom: 225px; } }
    &-border {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 47px 0 20px 0;
        z-index: 10;
        position: relative;
        @include sm {
            padding: 20px 0 0 0; } } }
.accordion {
    padding: 35px 70px 5px 120px;
    @include lg {
        padding: 35px 70px 5px 120px; }
    @include md {
        padding: 0px 30px; }
    @include sm {
        padding: 25px 40px; }
    @include xs {
        padding: 35px 10px; }
    &-question {
        color: #464646;
        font-family: 'MulerBold';
        font-size: 20px;
        font-weight: 400;
        line-height: 23.98px;
        position: relative;
        @include sm {
            font-size: 16px; }
        &:before {
            content: attr(data-text);
            color: #e13e46;
            font-family: 'MulerBold';
            font-size: 20px;
            font-weight: 400;
            line-height: 23.98px;
            padding-right: 5px;
            @include sm {
                font-size: 18px; } } }
    &-text {
        color: #464646;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        padding-top: 24px;
        line-height: 22px;
        @include sm {
            font-size: 14px; } } }

.accordion-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px; }
.accordion__item {
    margin-bottom: 40px;
    cursor: pointer;
    @include sm {
        margin-bottom: 20px; } }

.accordion-body {
    color: #3f3c3c;
    display: none; }
.accordion-body__contents {
    padding: 1.5em 1.5em;
    font-size: .85em; }
.accordion__item  .accordion-question:after {
    content: "";
    display: block;
    background: url("../img/arrow.svg") center no-repeat;
    width: 15px;
    height: 15px;
    font-size: 1.2em;
    float: right;
    position: relative;
    top: 0px;
    transition: .3s all;
    transform: rotate(-90deg);
    opacity: 0.25;
    @include sm {
        width: 12px;
        height: 12px;
        background-size: contain;
        top: 5px; } }
.accordion__item.active .accordion-question:after {
    transform: rotate(0deg);
    opacity: 1; }
