.header {
  padding: 17px 0;
  min-height: 350px;
  background: url("../img/header-bg.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 10;
  position: relative;
  &-width--mobile {
    @include sm {
      display: block; } }
  &-main {
    padding: 17px 0;
    min-height: 800px;
    background-size: cover;
    width: 100%;
    background: url("../img/header-main.jpg") center no-repeat;
    position: relative;
    z-index: 10;
    background-size: cover;
    @include md {
      position: static; }
    @include sm {
      padding: 25px 0 50px 0; } }
  &-triangle {
    border-bottom: 388px solid #bd1f26;
    border-left: 456px solid transparent;
    position: absolute;
    right: 0;
    bottom: 0;
    @include xl {
      border-bottom: 300px solid #bd1f26;
      border-left: 350px solid transparent; }
    @include lg {
      display: none; } }
  &-item {
    display: flex;
    align-items: center;
    @include lg {
      justify-content: space-between; } }
  &-logo {
    z-index: 100;
    position: relative;
    @include sm {
      max-width: 90%; } }
  &-list {
    color: #333333;
    font-family: 'GothamPro-Bold';
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    &:hover {
      color: #a21f25; } }
  &-scroll {
    position: absolute;
    left: -180px;
    display: flex;
    align-items: center;
    bottom: -20px;
    cursor: pointer;
    transform: rotate(-90deg);
    transition: .4s ease;
    &:hover {
      text-decoration: none; }
    @include lg {
      bottom: 50px; }
    @include md {
      bottom: 140px;
      display: none; } }
  &-items {
    @include lg {
      margin-right: 20px; } }
  &-hand {
    transform: rotate(90deg);
    margin-left: 21px;
    padding: 2px 4px;
    animation: infinite 1.7s ease hand-animation;
    box-shadow: 0 0 0 rgba(#bd1f26, 0.4); }
  &-row {
    align-items: center; }
  &-text {
    color: #333333;
    font-family: 'GothamPro-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    padding:  0 54px;
    @include xl {
      padding: 0 30px;
      font-size: 12px; }
    &--red {
      color: #e13e46 !important; }
    &--white {
      color: #ffffff !important; } }
  &-nav {
    margin-left: 54px;
    @include lg {
      margin-left: 20px;
      display: none; }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: block;
        a {
          color: #333333;
          font-family: 'GothamPro-Bold';
          font-size: 14px;
          font-weight: 700;
          line-height: 26px;
          text-transform: uppercase;
          position: relative;
          transition: .3s ease;
          &:hover {
            color: #da0503;
            text-decoration: none; }
          &:hover:before {
            opacity: 1; }
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            opacity: 0;
            background-color: #dd252e;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: -8px;
            transition: .3s ease;
            top: 50%;
            transform: translate(0,-50%); } } } } }
  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include xl {
      justify-content: flex-end; }
    @include lg {
      display: none; }
    @include sm {
      margin-top: 20px;
      position: relative;
      top: 10px;
 } }      // padding-left: 40px
  &-mobile {
    display: block; }
  &-img {
    margin-right: 21px;
    animation: rotate__phone infinite .5s ease; }
  &-phone {
    color: #333333;
    font-family: 'GothamPro-Bold';
    font-size: 18px;
    font-weight: 700;
    line-height: 22.27px;
    @include xl {
      font-size: 14px; }
    &:hover {
      text-decoration: none;
      color: #d90504; } }
  &-desc {
    color: #8f8e8e;
    font-family: 'MullerRegular';
    font-size: 14px;
    font-weight: 400;
    line-height: 25.05px; }
  &-span {
    color: #333333;
    font-family: 'GothamPro-Bold';
    font-weight: 700;
    font-size: 14px;
    line-height: 25.05px; }
  &-social {
    display: flex;
    align-items: center;
    @include lg {
      padding-bottom: 30px;
      padding-top: 50px; } }
  &-button {
    color: #d90504;
    font-family: 'GothamPro-Bold';
    font-size: 12px;
    font-weight: 700;
    line-height: 48px;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 0.65px;
    width: 179px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #ff0000;
    opacity: 0.9;
    transition: .4s ease;
    @include sm {
      font-size: 10px; }
    &:focus {
      outline: none; }
    &:hover {
      background-image: #ff0000;
      background-color: #ff0000;
      color: #ffffff;
      border: none;
      background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%);
 } }      // border-color: linear-gradient(to top, #bd1f26 0%, #e23e46 100%)
  &-lead {
    padding-top: 90px; }
  &-title {
    color: #333333;
    font-family: 'MulerBold';
    font-size: 52px;
    font-weight: 400;
    line-height: 60px;
    position: relative;
    display: block;
    &--bottom {
      &:after {
        content: "";
        display: block;
        border-bottom: 5px solid rgba(#424242,0.14);
        width: 37.5%;
        margin-top: -17px;
        @include xl {
          width: 45%; }
        @include lg {
          width: 60%; }
        @include md {
          width: 50%;
          margin-top: -14px; }
        @include sm {
          width: 260px; } } }
    @include md {
      font-size: 32px;
      line-height: 40px; } }
  &-subtitle {
    color: #1a1a1a;
    font-family: 'MullerRegular';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.17px;
    padding: 33px 0 0 67px;
    @include sm {
      font-size: 14px;
      padding-left: 0px; } }
  &-group {
    padding-top: 65px;
    display: flex;
    align-items: center;
    @include sm {
      flex-direction: column; } }
  &-circle {
    transform: rotate(-90deg);
    margin: 0 21px 0 35px;
    display: block;
    span {
      &:nth-child(3) {
        width: 9px;
        height: 9px;
        border: 2px solid #e23e46;
        display: block;
        border-radius: 50%;
        margin: 7px; }
      &:nth-child(2) {
        margin: 7px;
        width: 9px;
        height: 9px;
        border: 2px solid rgba(#e23e46,0.5);
        display: block;
        border-radius: 50%; }
      &:nth-child(1) {
        margin: 7px;
        width: 9px;
        height: 9px;
        border: 2px solid rgba(#e23e46,0.25);
        display: block;
        border-radius: 50%; } } } }
.header-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: none;
  background: transparent;
  overflow: hidden;
  transition: all 0.7s ease-out, background 1s ease-out;
  z-index: 1;
  .burger-container {
    position: absolute;
    right: 20px;
    top: 35px;
    display: inline-block;
    height: 50px;
    width: 50px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.3s $cubic;
    user-select: none;
    z-index: 9999;
    -webkit-tap-highlight-color: transparent;
    #burger {
      width: 24px;
      height: 8px;
      position: relative;
      display: block;
      margin: -4px auto 0;
      top: 45%;
      .bar {
        width: 100%;
        height: 2px;
        display: block;
        position: relative;
        background: #a21f25;
        transition: all 0.3s $cubic;
        transition-delay: 0s;
        &.topBar {
          transform: translateY(0px) rotate(0deg); }
        &.mdlBar {
          transform: translateY(5px) rotate(0deg); }
        &.btmBar {
          transform: translateY(10px) rotate(0deg); } } } }
  @include lg {
    display: block; }
  ul.menu {
    position: relative;
    display: block;
    padding: 0px 40px 0;
    list-style: none;
    top: 150px;
    li.menu-item {
      margin-top: 5px;
      transform: translateX(80px);
      opacity: 0;
      transition: transform .7s $cubic, opacity 0.8s $cubic;
      // @for $i from 1 through $menuItems
      //   &:nth-child(#{$i})
      //     transition-delay: #{0.56 - ($i * 0.07)}s;
      a {
        color: #333333;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        &:hover {
          text-decoration: none; }
        &.active {
          color: #da0503;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            background-color: #dd252e;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translate(0,-50%); } } } } }
  &.menu-opened {
    height: 100vh;
    z-index: 9999;
    background-color: #ffffff;
    transition: all 0.1s ease-in, background 0.7s ease-in;
    // transition-delay: 0.25s;
    position: fixed;
    right: 0;
    .burger-container {
      // transform: rotate(90deg);
      #burger {
        .bar {
          transition: all 0.4s $cubic;
          transition-delay: 0.2s;
          &.topBar {
            transform: translateY(5px) rotate(45deg); }
          &.mdlBar {
            opacity: 0; }
          &.btmBar {
            transform: translateY(1px) rotate(-45deg); } } } }
    ul.menu {
      li.menu-item {
        transform: scale(1) translateY(0px);
        opacity: 1;
        @for $i from 1 through $menuItems {
          &:nth-child(#{$i}) {
              transition-delay: #{0.07 *$i+0.25}s; } } } } } }
@keyframes rotate__phone {
 50% {
    transform: rotate(-20deg); }
 100% {
    transform: rotate(20deg); } }
@keyframes hand-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(#bd1f26, 0.4);
    border-radius: 50%; }
  70% {
    box-shadow: 0 0 0 10px rgba(#bd1f26, 0);
    border-radius: 50%; }
  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(#bd1f26, 0); } }
