@font-face {
	font-family: 'MulerBold';
	src: url('../fonts/MullerBold.eot') format('embedded-opentype'),url('../fonts/MullerBold.ttf') format('ttf'),url('../fonts/MullerBold.woff') format('woff'); }

@font-face {
	font-family: 'MullerRegular';
	src: url('../fonts/MullerRegular.eot') format('embedded-opentype'),url('../fonts/MullerRegular.ttf') format('ttf'),url('../fonts/MullerRegular.woff') format('woff'); }
@font-face {
	font-family: 'GothamPro-Bold';
	src: url('../fonts/GothamPro-Bold.eot') format('embedded-opentype'),url('../fonts/GothamPro-Bold.ttf') format('ttf'),url('../fonts/GothamPro-Bold.woff') format('woff'); }
@font-face {
	font-family: 'GothamPro-Regular';
	src: url('../fonts/GothamPro.eot') format('embedded-opentype'),url('../fonts/GothamPro.ttf') format('ttf'),url('../fonts/GothamPro.woff') format('woff'); }

@mixin font-fallback($font) {
	font-family: $font, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

$xl-width: 1200px;
$lg-width: 992px;
$md-width: 767px;
$sm-width: 576px;
$mobile-width: 400px;
$xs-width: 365px;

@mixin xl {
	@media (max-width: #{$xl-width - 1px}) {
		@content; } }
@mixin lg {
	@media (max-width: #{$lg-width - 1px}) {
		@content; } }
@mixin md {
	@media (max-width: #{$md-width - 1px}) {
		@content; } }
@mixin sm {
	@media (max-width: #{$sm-width - 1px}) {
		@content; } }
@mixin mobile {
	@media (max-width: #{$mobile-width - 1px}) {
		@content; } }
@mixin xs {
	@media (max-width: #{$xs-width - 1px}) {
		@content; } }

$browser-context: 16px;

@function rem($pixels) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@return $pixels / $browser-context * 1rem; }

@function em($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px; }

	@if (unitless($context)) {
		$context: $context * 1px; }

	@return $pixels / $context * 1em; }

// retina mixins
@mixin retina2x {
	@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
		@content; } }
@mixin retina3x {
	@media (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi) {
		@content; } }
