.section {
    position: relative;
    z-index: 0;
    &-title {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 44px;
        font-weight: 400;
        line-height: 54px;
        text-align: left;
        z-index: 10;
        position: relative;
        @include lg {
            z-index: 0; }
        @include sm {
            font-size: 32px;
            line-height: 40px; } }
    &-subtitle {
        display: block;
        color: #e13e46; }
    &-button {
        width: 300px;
        height: 70px;
        border-radius: 5px;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        background-color: #9dc6cc;
        border: none;
        border-bottom: 5px solid #a21f25;
        background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%);
        position: relative;
        transition: .2s ease;
        overflow: hidden;
        z-index: 10;
        box-shadow: 0 4px 13px rgba(226, 62, 70, 0.25);
        @include lg {
            z-index: 0; }
        &:focus {
            outline: none; }
        &:active {
            border-bottom: 0px; }
        @include sm {
            width: 80%;
            height: 65px;
            font-size: 12px; }
        @include xs {
            font-size: 10px;
            height: 60px; }
        &:after {
            content: "";
            cursor: pointer;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            z-index: 0;
            width: -webkit-calc(100% + 3000px);
            width: calc(100% + 3000px);
            margin-left: -1500px;
            background: -webkit-linear-gradient(135deg, transparent 310px, #fff 310px, #fff 325px, transparent 325px);
            background: -o-linear-gradient(135deg, transparent 310px, #fff 310px, #fff 325px, transparent 325px);
            background: linear-gradient(-45deg, transparent 310px, #fff 310px, #fff 325px, transparent 325px);
            background-position: right;
            background-repeat: no-repeat;
            -webkit-background-size: 1000px 1000px;
            background-size: 1000px;
            -webkit-transition-timing-function: 3s;
            -o-transition-timing-function: 3s;
            transition-timing-function: 3s;
            -webkit-animation-name: puls__cta;
            animation-name: puls__cta;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-duration: 4.6s;
            animation-duration: 4.6s;
            opacity: 1; }
        &:hover {
            background-image: linear-gradient(to top, #bd1f26 20%, #e23e46 80%); } }
    &-btn {
        width: 300px;
        transition: .4s ease;
        height: 70px;
        border-radius: 4px;
        background-color: #ffffff;
        position: relative;
        color: #333333;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        border: none;
        font-weight: 700;
        z-index: 10;
        text-transform: uppercase;
        margin-left: 18px;
        letter-spacing: 1.4px;
        text-align: right;
        padding-right: 25px;
        &:focus {
            outline: none; }
        @include md {
            padding-right: 35px; }
        @include sm {
            width: 80%;
            height: 65px;
            font-size: 12px;
            margin-left: 0px;
            margin-top: 15px;
            text-align: center;
            padding-left: 10%; }
        @include xs {
            font-size: 10px;
            height: 60px; }
        &:hover:before {
            transform: scale(1.03); }
        &:before {
            content: "";
            display: block;
            transition: .5s ease;
            background: url("../img/btn-before.png") center no-repeat;
            min-height: 83px;
            width: 103px;
            position: absolute;
            left: -4px;
            top: 0;
            @include md {
                width: 70px;
                background-size: contain; } } }
    &-span {
        display: block;
        font-family: 'GothamPro-Regular';
        font-size: 9px;
        font-weight: 300;
        padding-top: 5px;
        color: rgba(#ffffff,.9);
        line-height: normal;
        letter-spacing: 0.1px; } }
.m-button {
    margin-top: 85px; }


@keyframes puls__cta {
    0% {
        background-position: left; }
    99% {
        background-position: right; } }
