.service {
    padding: 58px 0 90px 0;
    &-row {
        padding-top: 105px;
        @include lg {
            padding-top: 0px; } }
    &-tablet--top {
        @include lg {
            margin-top: 100px; } }

    &-item {
        position: relative;
        z-index: 10;
        margin-left: 67px;
        @include lg {
            margin-bottom: 100px;
            margin-left: 0px;
            position: static; }
        @include md {
            margin-bottom: 80px;
            margin-left: 0px; }
        @include sm {
            margin-bottom: 100px; }
        &:before {
            content: attr(data-text);
            display: block;
            color: #ebebeb;
            font-family: 'MulerBold';
            font-size: 80px;
            position: absolute;
            top: -30px;
            left: 10px;
            z-index: -10;
            font-weight: 400;
            line-height: 54px;
            @include sm {
                font-size: 70px; } } }
    &-head {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        padding-bottom: 5px; }

    &-text {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        @include sm {
            font-size: 14px; } }
    &-block {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 67px;
        @include md {
            margin-top: 50px; }
        @include sm {
            margin-top: 0px;
            flex-wrap: wrap; } }
    &-main {
        margin-left: 63px;
        @include sm {
            margin: 20px 0 0 20px;
            text-align: center; }
        @include mobile {
            margin: 30px 0 0 15px; } }
    &-lead {
        color: #333333;
        font-family: 'GothamPro-Regular';
        font-size: 15px;
        font-weight: 400;
        line-height: 27.99px;
        position: relative;
        @include sm {
            font-size: 14px; }
        &--bold {
            font-family: 'GothamPro-Bold';
            color: #333333;
            font-size: 15px;
            font-weight: 400;
            line-height: 27.99px;
            position: relative;
            @include sm {
                font-size: 14px; } }
        &:before {
            content: "";
            display: block;
            width: 82px;
            min-height: 28px;
            background: url("../img/border-service.png") center no-repeat;
            position: absolute;
            left: -75px;
            bottom: -55px;
            background-size: cover;
            @include sm {
                display: none; } } } }
.service-border.service-border {
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 82px;
        min-height: 28px;
        background: url("../img/border-after.png") center no-repeat;
        position: absolute;
        left: -75px;
        bottom: -55px;
        background-size: cover;
        @include sm {
            bottom: -10px;
            left: -95px;
            transform: rotate(30deg); }
        @include mobile {
            transform: rotate(80deg);
            width: 45px;
            left: -50px;
            bottom: 20px; } } }
.m-l-item {
    margin-left: 262px;
    @include xl {
        margin-left: 150px; }
    @include lg {
        margin-left: 0; } }
