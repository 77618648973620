@import 'variables';
@import 'mixins';
@import '../../node_modules/normalize.css/normalize';
@import '../../node_modules/swiper/dist/css/swiper';
//@import '../../node_modules/bootstrap/dist/css/bootstrap.min'
@import 'reset';
@import 'header';
@import 'section';
@import 'modals';
@import 'about';
@import 'service';
@import 'system';
@import 'preim';
@import 'article';
@import 'company';
@import 'work';
@import 'difficult';
@import 'question';
@import 'manager';
@import 'blog';
@import 'footer';

.preloader {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	z-index: 9992;
	&-logo {
		z-index: -1;
		max-width: 100%;
		object-fit: contain;
		order: 1;
		margin: 50px 0;
		@include sm {
			max-width: 40%;
			margin: 40px 0; } } }
.loader {
	width: 200px;
	height: 2px;
	background: #f2f2f2;
	overflow: hidden;
	order: 2;
	@include sm {
		width: 120px;
		height: 2px; } }
.a-bounce {
	animation: 3.5s alternate infinite bounce-item ease-in-out; }
@keyframes bounce-item {
	0% {
		width: 0%;
		transform: translateX(0%); }

	50% {
		transform: translateX(0%); }

	100% {
		width: 100%;
		transform: translateX(100%); } }

.loader-line {
    height: 3px;
    background: #a7384c; }

.wrapper {
	overflow: hidden;
	position: relative;
	@include lg {
		overflow-x: hidden;
		overflow-y: auto; }
 }	// z-index: 0

@keyframes glitch-anim {
	$steps: 20;
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			clip: rect(random(100)+px, 9999px, random(100)+px, 0);
			transform: skew((random(100) / 100) + deg); } } }
@keyframes glitch-anim2 {
	$steps: 20;
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			clip: rect(random(100)+px, 9999px, random(100)+px, 0);
			transform: skew((random(100) / 100) + deg); } } }

@keyframes glitch-skew {
	$steps: 10;
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			transform: skew((random(10) - 5) + deg); } } }
