* {
	box-sizing: border-box; }

a, input, select, textarea {
	&:focus {
		outline: 0; } }
a {
	color: #000000; }
h1,h2,h3,h4,h5,h6,p {
	margin: 0;
	padding: 0; }
button {
	cursor: pointer;
	border: none;
	&:focus {
		outline: none; } }

html {
	@include md {
		font-size: .9rem; }
	@include sm {
		font-size: .8rem; } }


body {
	font-size: 1rem;
	font-family: 'MullerRegular'; }
.body-overflow {
	overflow: hidden;
	@include sm {
		overflow: auto; } }
.border-row {
	position: relative;
	margin: 0 auto;
	max-width: 1110px;
	@include xl {
		display: none;
		max-width: auto;
		position: static; } }
.border-column {
	width: 1px;
	overflow: hidden;
	position: absolute;
	height: 8200px;
	left: 0;
	top: 0;
	// background-color: red
	background-color: rgba(#e1e6e6,.1);
	@include xl {
		display: none;
		height: auto;
		position: static; }
	&:nth-child(2) {
		left: 19.8%; }
	&:nth-child(3) {
		left: 41%; }
	&:nth-child(4) {
		left: 61%; }
	&:nth-child(5) {
		left: 85%; }
	&:nth-child(6) {
		left: 100%; } }
.t-c {
	display: flex;
	justify-content: center; }
.p-r {
	position: relative;
	@include lg {
		position: static; } }
.table.table {
	margin-top: 42px;
	@include sm {
		margin-top: 20px; } }
.table.table  thead th {
	border: none; }
.table td, .table.table th {
	border: none;
	padding: 12px 0;
	@include md {
		font-size: 14px; }
	@include sm {
		font-size: 12px;
		padding: 5px 2px; }
	@include xs {
		font-size: 10px;
		padding: 7px 0; } }
.table.table tbody tr {
	padding-bottom: 15px; }

.table-title {
	color: #e13e46;
	font-family: 'MulerBold';
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	@include sm {
		font-size: 14px; } }
.table-bold {
	color: #333333;
	font-family: 'MulerBold';
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
	@include sm {
		font-size: 16px;
		line-height: 18px; } }
.table-text {
	color: #333333;
	font-family: 'MullerRegular';
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	@include sm {
		font-size: 16px; } }
.table-link {
	color: #e13e46;
	font-family: 'MullerRegular';
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	cursor: pointer;
	text-decoration: underline;
	transition: .3s ease;
	@include md {
		font-size: 16px; }
	@include sm {
		font-size: 12px; }
	@include xs {
		font-size: 10px; }
	&:hover {
		color: #000000; } }
.table-bg {
	background-color: #f1f1f1; }
