.system {
    padding: 60px 0 45px 0;
    background-color: #fbfbfb;
    overflow: hidden;
    &:after {
        content: "";
        display: block;
        background: url("../img/system-bg.png") center no-repeat;
        min-height: 850px;
        width: 99%;
        background-size: contain;
        position: absolute;
        right: -138px;
        bottom: -5px;
        z-index: -10;
        overflow: hidden;
        @include lg {
            bottom: -90px; }
        @include md {
            display: none; } }
    &-title {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 44px;
        font-weight: 400;
        line-height: 42px;
        @include sm {
            font-size: 32px;
            line-height: 40px; } }
    &-span {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 32px;
        font-weight: 400;
        line-height: 42px;
        display: block;
        @include sm {
            font-size: 18px;
            line-height: 28px;
            padding-top: 5px; } }
    &-row {
        margin-top: 39px;
        margin-left: -15px; }
    &-img {
        display: block;
        position: relative;
        z-index: 999;
        @include lg {
            width: 100%;
            position: static; } }
    &-box {
        padding-top: 32px; }
    &-text {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        @include sm {
            font-size: 14px; } }
    &-desc {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 16px;
        font-weight: 400;
        padding: 10px 0;
        line-height: 22px;
        @include sm {
            font-size: 14px; } }
    &-nav {
        padding: 10px 0;
        ul {
            list-style-type: none;
            margin: 0;
            padding-left: 20px;
            li {
                color: #333333;
                font-family: 'MullerRegular';
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                @include sm {
                    font-size: 14px; } } } }

    &-phone {
        color: #333333;
        font-size: 16px;
        font-family: 'MulerBold';
        font-weight: 700;
        line-height: 30px;
        @include sm {
            font-size: 14px; } }
    &-block {
        padding-top: 54px;
        display: flex;
        justify-content: center;
        a {
            @include sm {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center; } } } }

