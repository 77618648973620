.article {
    padding: 59px 0 50px 0;
    &-content {
        padding: 70px 0 60px 0;
        @include sm {
            padding: 50px 0 40px 0; } }
    &-row {
        padding: 59px 0 44px 0; }

    &-img {
        display: block;
        width: 95%;
        margin-bottom: 30px;
        position: relative;
        z-index: 3;
        @include sm {
            margin-bottom: 10px; } }
    &-box {
        width: 100%;
        display: block;
        height: 100%;
        position: relative;
        z-index: 10;
        margin-bottom: 65px;
        @include sm {
            margin-bottom: 70px; } }
    &-info {
        position: absolute;
        bottom: 40px;
        left: 32px;
        max-width: 80%;
        z-index: 999;
        @include lg {
            bottom: 120px;
            left: 50px; }

        @include md {
            bottom: 90px;
            left: 40px; }
        @include xs {
            left: 25px; } }
    &-image {
        width: 95%;
        object-fit: none;
        transition: .3s ease;
        border-radius: 4px;
        @include lg {
            width: 100%;
            object-fit: cover;; } }

    &-item {
        // background-size: cover
        width: 100%;
        display: block;
        height: 100%;
        padding: 130px 43px 40px 18px;
        transition: .3s ease;
        border-radius: 4px;
        z-index: 10;
        position: relative;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
        @include xl {
            padding: 80px 43px 30px 18px; }
        @include lg {
            background-size: cover !important;
            padding: 210px 43px 70px 45px;
            box-shadow: none;
            margin-bottom: 19px; }
        @include md {
            padding-top: 50px;

            padding-bottom: 0px;
            margin-bottom: 0px;
            padding-bottom: 50px;
            padding-left: 50px;
            padding-right: 40px;
            background-size: contain !important; }

        @include sm {
            padding: 250px 70px 20px 95px;
            margin-bottom: 100px; }
        @include mobile {
            padding: 206px 70px 20px 55px;
            margin-bottom: 50px;
            background-size: cover;
            background-position: 0% 100%; }
        // +mobile
        //     padding: 170px 40px 60px 45px
        //     margin-bottom: 90px
        @include xs {
            margin-bottom: 0px;
            padding: 148px 40px 70px 45px; }
        &:hover {
            transform: translateY(-2px); } }
    &-title {
        color: #ffffff;
        font-family: 'MulerBold';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
        /* Text style for "з, аголово" */
        letter-spacing: 0.48px;
        padding-bottom: 15px; }
    &-text {
        color: rgba(#ffffff,0.8);
        font-family: 'MullerRegular';
        font-size: 14px;
        font-weight: 400;
        line-height: 22.75px; }
    &-link {
        color: #e13e46;
        font-family: 'MullerRegular';
        font-size: 14px;
        font-weight: 400;
        padding-top: 30px;
        display: block;
        line-height: 22.75px;
        text-decoration: underline;
        &:hover {
            color: rgba(#e13e46,.9); } }
    &-block {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            @include sm {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center; }
            &:hover {
                text-decoration: none; } } } }
.p-r-n {
    padding-right: 0px;
    @include sm {
        padding-right: 15px; } }
.c-w-text {
    color: #ffffff; }
