.about {
    padding: 59px 0 81px 0;
    text-align: center;
    background-color: #fbfbfb;
    @include sm {
        padding: 40px 0 81px 0; }
    &-tab {
        border: 1px solid rgba(#000000,0.1);
        border-radius: 5px; }
    &-border {
        margin-top: 50px;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        position: relative;
        z-index: 1;
        border-radius: 5px;
        @include sm {
            padding: 5px; }
        // &:after
        //     content: ""
        //     width: 98%
        //     overflow: hidden
        //     z-index: -1
        //     display: block
        //     position: absolute
        //     bottom: -10px
        //     height: 100px;
        //     box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        //     border-radius: 5px;
        //     background-color: #ffffff
        //     +md
        //         width: 96%
 }        //         left: 2%
    &-shadow {
        display: block;
        position: absolute;
        bottom: -10px;
        width: 94%;
        left: 3%;
        height: 100px;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background-color: #ffffff;
        &--mod {
            bottom: 790px;
            left: 3%;
            @include xs {
                bottom: 785px; } }
        @include md {
            width: 89%;
            left: 5.5%; } }
    &-box {
        margin-top: 77px;
        text-align: left;
        padding-right: 19px;
        height: 570px;
        overflow: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar-track {
            background-color: #ecedee; }
        &::-webkit-scrollbar {
            width: 2px;
            background-color: #ecedee; }
        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #333333;
            background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%); } }

    &-desc {
        color: #333333;
        line-height: 22px;
        font-family: 'MulerBold';
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 30px;
        @include sm {
            font-size: 18px; } }
    &-description {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        padding-left: 29px;
        padding-bottom: 7px;
        @include md {
            padding-bottom: 20px; }
        @include sm {
            font-size: 14px; } }
    &-text {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 30px;
        line-height: 22px;
        @include sm {
            font-size: 14px; } }
    &-nav {
        padding-bottom: 30px;
        ol {
            margin: 0;
            padding-left: 20px;
            li {
                color: #333333;
                font-family: 'MullerRegular';
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                @include sm {
                    font-size: 14px; } } } }
    &-menu {
        padding-bottom: 30px;
        ul {
            margin: 0;
            padding-left: 20px;
            li {
                color: #333333;
                font-family: 'MullerRegular';
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                @include sm {
                    font-size: 14px; } } } }
    &-product {
        max-width: 100%;
        display: block; }
    &-blocks {
        @include lg {
            margin-top: 50px; } }
    &-row {
        margin-top: 64px;
        &--main {
            padding: 38px 0 0 0;
            margin: -1px 0 -1px -1px; }

        &--border-n {
            border-top: none !important; }
        &--m-b {
            padding-bottom: 33px;
            margin: -1px 0 -1px -1px; } }
    &-boxes {
        padding: 39px 0 0 71px;
        text-align: left; }
    &-price {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 11px; }
    &-coin {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 31px; }
    &-head {
        color: #e13e46;
        font-family: 'MulerBold';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @include sm {
            font-size: 14px;
            max-width: 50%;
            flex: 0 0 50%;
            text-align: left; } }
    &-large {
        font-family: 'MulerBold';
        font-weight: 400;
        line-height: 24px;
        font-size: 36px; }
    &-subhead {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @include sm {
            font-size: 14px;
            max-width: 50%;
            flex: 0 0 50%;
            text-align: right; } }
    &-line {
        display: flex;
        justify-content: space-between;
        padding: 8px 25px 8px 25px;
        @include sm {
            padding: 10px; } }
    &-bg {
        background-color: #f1f1f1; }
    &-group {
        overflow: hidden;
        cursor: pointer;
        &:hover .iron-img {
            transform: scale(1.08); } }
    &-linear {
        display: block;
        border: 1px solid rgba(0,0,0,0.08);
        height: 100%;
        border-right: none;
        border-bottom: none;
        padding: 0; }

    &-item {
        @include md {
            margin-bottom: 10px; } }
    &-link {
        width: 150px;
        padding: 19px 0;
        display: block;
        border-radius: 3px;
        background-color: #d5d5d5;
        margin-right: 10px;
        color: #ffffff;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        font-weight: 700;
        line-height: 24.02px;
        text-transform: uppercase;
        /* Text style for "А3/A500C" */
        letter-spacing: 1.4px;
        @include sm {
            width: 100%;
            margin-bottom: 10px;
            font-size: 12px;
            padding: 17px 0; }
        &:hover {
            text-decoration: none;
            background: #000;
            color: #ffffff; }
        &.active {
            background-color: #d5d5d5;
            background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%); } }
    &-sublink {
        width: 250px;
        padding: 23px 0;
        display: block;
        border-radius: 3px;
        background-color: #d5d5d5;
        margin-right: 10px;
        color: #ffffff;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        font-weight: 700;
        line-height: 24.02px;
        text-transform: uppercase;
        /* Text style for "А3/A500C" */
        letter-spacing: 1.4px;
        @include lg {
            width: 200px;
            font-size: 12px; }
        @include md {
            width: 100%; }
        @include sm {
            font-size: 11px; }
        &:hover {
            text-decoration: none;
            background: #8a8a8a;
            color: #ffffff; }
        &.active {
            background-color: #d5d5d5;
            background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%); } } }
.tab-nav {
    width: 490px;
    padding: 10px 9px;
    border-radius: 4px;
    border: 1px solid rgba(#000000,0.15);
    margin: 0 auto;
    margin-top: 18px;
    @include md {
        width: 100%;
        border: none; }
    @include sm {
        display: block; } }
.tab-menu {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }

.tab-list {
    width: 790px;
    padding: 10px 9px;
    border-radius: 4px;
    border: 1px solid rgba(#000000,0.15);
    margin: 0 auto;
    margin-top: 18px;
    @include lg {
        width: 100%; }
    @include md {
        flex-direction: column;
        border: none;
        padding: 10px 20px; } }


//     display: flex
//     justify-content: center
//     align-items: center
//     border: 1px solid red
.m-r-none {
    margin-right: 0px; }
.iron-img {
    max-width: 100%;
    min-height: 220px;
    object-fit: cover;
    display: block;
    transition: .4s ease;
    @include md {
        width: 100%; } }
.p-rel.p-rel {
    position: relative; }
