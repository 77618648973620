.modals {
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    display: none !important;
    top: 0;
    z-index: 10000;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    &-border {
        padding: 10px;
        border-radius: 5px;
        background: #ffffff;
        position: relative;
        &:after {
            content: "";
            width: 96%;
            z-index: -1;
            display: block;
            position: absolute;
            bottom: -10px;
            height: 100px;
            left: 2%;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            background-color: #e4e4e4; } }
    &-pop {
        border: 1px solid rgba(#000000,0.1);
        padding: 39px 40px 5px 40px;
        width: 420px;
        text-align: center;
        height: 520px;
        margin: 0 auto;
        // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        background-color: #ffffff;
        @include md {
            width: 100%; }
        @include sm {
            padding: 30px 20px;
            height: auto; } }
    &-title {
        color: #181818;
        font-family: 'MulerBold';
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
        @include sm {
            font-size: 28px;
            line-height: 28px; } }
    &-text {
        color: #000000;
        font-family: 'GothamPro-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 23.99px;
        padding: 20px 0 30px 0;
        @include sm {
            font-size: 14px; } }
    &-input {
        box-shadow: inset 0 5px 5px rgba(26, 26, 25, 0.1);
        border-radius: 6px;
        background-color: #f7f7f7;
        display: block;
        border: none;
        padding: 22px 0 22px 40px;
        color: rgba(#000000,0.9);
        width: 100%;
        font-family: 'GothamPro-Regular';
        font-size: 16px;
        margin-bottom: 14px;
        font-weight: 400;
        line-height: 29.98px;
        border: 0.5px solid transparent;
        transition: .3s ease;
        &--bottom {
            margin-bottom: 24px; }
        &:focus {
            box-shadow: 0 0px 1px 1px rgba(#ca3439, .6); }
        @include sm {
            font-size: 14px;
            padding: 15px 0 15px 30px; } }
    &-button {
        color: #ffffff;
        // font-family: 'MuseoSansCyrillic-500';
        font-size: 1em;
        font-weight: 400;
        line-height: 49px;
        width: 230px;
        border: none;
        height: 49px;
        margin-top: 30px;
        box-shadow: 0 5px 21px rgba(125, 39, 54, 0.36);
        background: #000;
        transition: .3s ease;
        @include lg {
            margin-right: 50px;
            width: 190px;
            height: 45px;
            line-height: normal;
            z-index: 9999;
            font-size: 14px; }
        @include md {
            margin: 30px auto 0; }
        &:hover {
            background: #000; } }
    &-desc {
        color: #000000;
        font-family: 'GothamPro-Regular';
        padding-top: 19px;
        font-size: 13px;
        font-weight: 400;
        line-height: 19.98px;
        @include sm {
            max-width: 100%;
            font-size: 12px; } }
    &-link {
        color: #d13037;
        text-decoration: underline;
        transition: .3s ease;
        &:hover {
            color: #000000; } }
    &-exit {
        width: 36px;
        height: 36px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        position: absolute;
        border-radius: 50%;
        right: -18px;
        z-index: 100;
        display: block;
        cursor: pointer;
        top: -19px;
        transition: .3s ease;
        // +md
        //     top: -60px
        //     right: 20px
        @include sm {
            right: 10px;
            top: -50px; }
        // +xs
        //     top: 20px
        //     right: 15px
        &:hover {
            background-color: #9dc6cc;
            transition: .2s ease;
            background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%); }
        &:hover span {
            background: #ffffff; }
        span {
            width: 17px;
            height: 2px;
            background-color: #000000;
            display: block;
            left: 10px;
            top: 16px;
            border-radius: 10px;
            cursor: pointer;
            position: absolute;
            @include sm {
                // top: 20px
                top: 17px;
 }                // left: 13px
            &:nth-child(1) {
                transform: rotate(45deg); }
            &:nth-child(2) {
                transform: rotate(-45deg); } } } }
.modal-animate {
    animation: scale .5s ease; }
@keyframes scale {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
.modal-active {
    display: flex !important; }
