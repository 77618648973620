.footer {
    padding: 29px 0;
    background-color: #fbfbfb;
    position: relative;
    z-index: 10;
    @include lg {
        position: absolute;
        width: 100%; }
    @include sm {
        position: static; }
    &-main {
        display: flex;
        align-items: center;
        @include sm {
            flex-direction: column;
            justify-content: center; } }
    &-block {
        color: #000000;
        font-family: 'MullerRegular';
        padding-left: 29px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        @include lg {
            padding-left: 50px; }
        @include md {
            padding-left: 10px; }
        @include sm {
            padding: 30px 0 30px 10px;
            text-align: center; } }
    &-span {
        font-family: 'MullerRegular';
        font-size: 13px;
        font-weight: 400;
        display: block;
        line-height: 20px;
        color: #e13e46;
        text-decoration: underline;
        &:hover {
            color: #000000; } }
    &-contact {
        display: flex;
        align-items: center;
        padding-left: 79px;
        @include xl {
            padding-left: 20px; }
        @include lg {
            padding-left: 70px; }
        @include md {
            padding-left: 10px; } }
    &-img {
        max-width: 100%;
        margin-right: 10px; }
    &-desc {
        color: #2c2c2c;
        line-height: 20px;
        font-family: 'MulerBold';
        font-size: 16px;
        font-weight: 700;
        @include sm {
            font-size: 14px; } }
    &-adress {
        color: #2c2c2c;
        line-height: 20px;
        font-family: 'MullerRegular';
        font-size: 16px;
        font-weight: 400;
        @include sm {
            font-size: 14px; } }
    &-description {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        @include lg {
            padding-top: 30px; }
        @include sm {
            flex-direction: column-reverse;
            justify-content: center; } }

    &-logotype {
        max-width: 100%;
        display: block;
        margin-right: 37px;
        @include xl {
            margin-right: 14px; }
        @include lg {
            padding-right: 60px; }
        @include md {
            padding-right: 30px; }
        @include sm {
            padding-right: 0px;
            padding-top: 25px; } }
    &-social {
        display: flex;
        align-items: baseline;
        margin-right: 7px; }
    &-lead {
        display: flex; }
    &-logo {
        @include md {
            max-width: 90%; }
        @include sm {
            max-width: 100%; } } }



