.work {
    width: 100%;
    min-height: 660px;
    background: url("../img/work-bg.png") center no-repeat;
    background-size: cover;
    padding: 58px 0 0 0;
    @include lg {
        min-height: 100%;
        padding: 60px 0; }
    &-row {
        padding-top: 47px;
        position: relative;
        right: 15px;
        @include md {
            right: 0px; } }
    &-shadow {
        width: 88%;
        display: block;
        position: absolute;
        bottom: -10px;
        height: 100px;
        left: 9%;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background-color: #ffffff;
        @include lg {
            bottom: 30px; }
        @include md {
            bottom: 15px;
            left: 6%; }
        @include sm {
            bottom: 20px; }
        &--tablet {
            bottom: 50px;
            @include lg {
                bottom: -10px; }
            @include md {
                bottom: 15px;
                left: 6%; }
            @include sm {
                bottom: 20px; } }
        &--mod {
            bottom: 50px;
            @include lg {
                bottom: 80px; }
            @include md {
                bottom: 15px; }
            @include sm {
                bottom: 20px; } } }
    &-item {
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background-color: #ffffff;
        // padding-top: 27px
        text-align: center;
        min-height: 380px;
        position: relative;
        z-index: 1;
        // z-index: 10
        // margin-right: 12px
        @include lg {
            min-height: 390px; }
        @include md {
            margin-bottom: 30px; }
        @include sm {
            min-height: 380px; }
        // &:after
        //     content: "";
        //     width: 94%;
        //     z-index: 1
        //     display: block;
        //     position: absolute;
        //     bottom: -10px;
        //     height: 100px;
        //     left: 3%
        //     box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
        //     border-radius: 5px;
        //     background-color: #ffffff;
        &--mod {
            min-height: 440px;
            @include lg {
                margin-bottom: 40px; }
            @include md {
                margin-bottom: 30px; } } }
    &-phone {
        color: #3e372e;
        font-family: 'MulerBold';
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        &:hover {
            text-decoration: none;
            color: rgba(#000000,.8); } }
    &-button {
        width: 240px;
        height: 70px;
        margin-top: 35px;
        border-radius: 4px;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        background-color: #9dc6cc;
        border: none;
        border-bottom: 5px solid #a21f25;
        background-image: linear-gradient(to top, #bd1f26 0%, #e23e46 100%);
        position: relative;
        transition: .4s ease;
        overflow: hidden;
        box-shadow: 0 4px 13px rgba(226, 62, 70, 0.25);
        &:focus {
            outline: none; }
        @include xl {
            width: 90%;
            height: 60px;
            font-size: 13px; }
        @include md {
            width: 300px;
            display: block;
            margin: 30px auto 0; }
        @include sm {
            width: 70%;
            height: 60px;
            font-size: 12px; }
        &:active {
            border-bottom: 0px; }
        &:after {
            content: "";
            cursor: pointer;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            width: -webkit-calc(100% + 3000px);
            width: calc(100% + 3000px);
            margin-left: -1500px;
            background: -webkit-linear-gradient(135deg, transparent 310px, #fff 310px, #fff 325px, transparent 325px);
            background: -o-linear-gradient(135deg, transparent 310px, #fff 310px, #fff 325px, transparent 325px);
            background: linear-gradient(-45deg, transparent 310px, #fff 310px, #fff 325px, transparent 325px);
            background-position: right;
            background-repeat: no-repeat;
            -webkit-background-size: 1000px 1000px;
            background-size: 1000px;
            -webkit-transition-timing-function: 3s;
            -o-transition-timing-function: 3s;
            transition-timing-function: 3s;
            -webkit-animation-name: puls__cta;
            animation-name: puls__cta;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-duration: 4.6s;
            animation-duration: 4.6s;
            opacity: 1; }
        &:hover {
            background-image: linear-gradient(to top, #bd1f26 20%, #e23e46 80%); } }
    &-img {
        max-width: 100%;
        display: block;
        object-fit: contain; }
    &-image {
        width: 100%;
        display: block;
        object-fit: contain;
        @include lg {
            width: auto;
            max-width: 100%;
            position: relative;
            top: -8px; } }
    &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 215px; }
    &-title {
        color: #2f2f2f;
        font-family: 'MulerBold';
        font-size: 16px;
        padding-bottom: 14px;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
        letter-spacing: 0.48px; }
    &-text {
        color: #807d79;
        font-family: 'MullerRegular';
        font-size: 14px;
        font-weight: 400;
        line-height: 22.75px; } }
.p-r-none {
    padding-right: 0px;
    @include sm {
        padding-right: 15px; } }
