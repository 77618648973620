.preim {
    padding: 61px 0 0px 0;
    background-color: #fbfbfb;
    &-title {
        background: linear-gradient(to top, #bd1f26 0%, #e23e46 100%);        background-clip: text;
        text-shadow: 0 3px 0 #a21f25;
        color: #e23e46;
        font-family: 'MulerBold';
        -webkit-background-clip: text;
        font-size: 50px;
        font-weight: 400;
        line-height: 48px;
        padding-bottom: 28px;
        @include sm {
            padding-bottom: 10px;
            font-size: 28px; } }
    &-text {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @include lg {
            margin-bottom: 40px; }
        @include sm {
            font-size: 14px; } }
    &-box {
        margin-left: 69px;
        @include sm {
            margin-left: 0; } } }
