.difficult {
    padding: 59px 0 79px 0;
    position: relative;
    overflow: hidden;
    @include md {
        padding: 59px 0 30px 0; }
    &:after {
        content: "";
        display: block;
        background: url("../img/work-triangle.png") center no-repeat;
        min-height: 663px;
        width: 100%;
        background-size: contain;
        position: absolute;
        right: -250px;
        bottom: -5px;
        z-index: -10;
        overflow: hidden;
        @include lg {
            bottom: -90px; }
        @include md {
            display: none; } }
    &-row {
        padding-top: 44px;
        align-items: baseline; }
    &-title {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 20px;
        font-weight: 400;
        padding-left: 11px;
        line-height: 54px;
        &--color {
            color: #e13e46; }
        @include md {
            padding-left: 0px; } }

    &-text {
        color: #333333;
        font-family: 'MullerRegular';
        font-size: 16px;
        padding-bottom: 20px;
        font-weight: 400;
        line-height: 24px;
        @include sm {
            font-size: 14px; }
        &--bottom {
            padding-bottom: 115px;
            @include lg {
                padding-bottom: 160px; }
            @include md {
                padding-bottom: 20px; } } }
    &-desc {
        color: #333333;
        font-family: 'MulerBold';
        font-size: 16px;
        font-weight: 400;
        padding-right: 17px;
        line-height: 24px;
        padding-bottom: 20px;
        @include sm {
            font-size: 14px; } }
    &-item {
        padding-left: 100px;
        @include lg {
            padding-left: 0; } } }
