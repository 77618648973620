.blog {
    padding: 134px 0 50px 0;
    @include lg {
        padding: 100px 0 50px 0; }
    @include sm {
        padding: 50px 0; }
    &-row {
        padding-top: 16px;
        &--bottom {
            padding-bottom: 60px;
            @include sm {
                padding-bottom: 80px; } } }
    &-nav {
        position: absolute;
        bottom: 0px;
        left: 54%;
        transform: translate(-54%,0);
        color: #e13e46;
        font-family: 'MullerRegular';
        font-size: 14px;
        font-weight: 400;
        line-height: 22.75px;
        z-index: 9999;
        @include xl {
            left: 55.3%;
            transform: translate(-55.3%,0); }
        @include lg {
            left: 56.5%;
            transform: translate(-56.5%,0); }
        @include md {
            left: 58%;
            transform: translate(-60%,0); }
        @include sm {
            left: 59%;
            transform: translate(-59%,0);
            bottom: 0px; } }

    &-button--top {
        margin-top: 45px; }
    &-next {
        background-image: none;
        right: auto !important;
        left: 0 !important;
        cursor: pointer;
        left: 0;
        display: block;
        &:focus {
            outline: none; } }
    &-prev {
        margin-right: 110px;
        cursor: pointer;
        background-image: none;
        right: 0 !important;
        left: auto !important;
        display: block;
        &:focus {
            outline: none; }
        @include sm {
            margin-right: 80px; } }
    &-section {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            @include sm {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center; }
            &:hover {
                text-decoration: none; } } } }
.swiper-pagination.swiper-pagination {
    bottom: -2px;
    @include sm {
        bottom: 50px; } }
.swiper-pagination-bullet-active.swiper-pagination-bullet-active {
    border: 2px solid #e23e46 !important;
    background: #ffffff !important; }
.swiper-pagination-bullet.swiper-pagination-bullet {
    border: 2px solid #a7a7a7;
    background: #ffffff;
    opacity: 1;
    width: 10px;
    height: 10px; }
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 1; }
