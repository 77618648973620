.company {
    padding: 0px 0 55px 0;
    @include sm {
        padding-bottom: 0px; }
    &-row {
        align-items: center;
        padding-top: 48px;
        @include sm {
            padding-bottom: 30px; } }
    &-img {
        max-width: 100%;
        object-fit: cover;
        @include md {
            margin-bottom: 20px; }
        @include sm {
            margin-bottom: 30px;
            max-width: 75%;
            object-fit: cover; } } }
.col-tablet-6.col-tablet-6 {
    @include md {
        max-width: 50%;
        flex: 0 0 50%; }
    @include sm {
        max-width: 50%;
        flex: 0 0 50%; } }
.bg-color {
    background-color: #fbfbfb; }
